import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Heading,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { PodmeColor } from "../../redux/types";
import { SubscriptionEvent, SubscriptionEventType } from "../redux/types";

interface Props {
    groupedEvents: { year: string; events: SubscriptionEvent[] }[];
}

function GetUserFriendlyEventName(text: string): string {
    var eventFromEnum = SubscriptionEventType[text as keyof typeof SubscriptionEventType];
    if (eventFromEnum) return eventFromEnum;

    // fallback to string split
    return text
        .replace(/([A-Z])/g, " $1")
        .trim()
        .toLowerCase()
        .replace(/^./, (str) => str.toUpperCase());
}

function GetPlanNameOnPlanChangeTooltip(subscriptionEvent: SubscriptionEvent): string {
    if (!subscriptionEvent.previousSubscriptionPlanName && subscriptionEvent.currentSubscriptionPlanName)
        return "Subscription plan: " + subscriptionEvent.currentSubscriptionPlanName;

    if (subscriptionEvent.currentSubscriptionPlanName !== subscriptionEvent.previousSubscriptionPlanName)
        return (
            "Subscription plan: " +
            subscriptionEvent.currentSubscriptionPlanName +
            ", transitioned from: " +
            subscriptionEvent.previousSubscriptionPlanName
        );

    return "";
}

function GetPlanNameOnPlanChange(subscriptionEvent: SubscriptionEvent): string {
    if (subscriptionEvent.currentSubscriptionPlanName !== subscriptionEvent.previousSubscriptionPlanName)
        return subscriptionEvent.currentSubscriptionPlanName;

    return "";
}

function SubscriptionEvents({ groupedEvents }: Props) {
    if (!groupedEvents || groupedEvents.length < 1) return null;

    return (
        <Flex direction="column" gridGap="1rem">
            <Heading as="h3" fontSize="1.25rem" fontWeight="700">
                Events
            </Heading>
            <Flex direction="column" gridGap="0.5rem">
                {groupedEvents.map(({ year, events }, idx) => (
                    <Flex flexDirection="column" key={idx} backgroundColor="#e9e9e9" rounded="0.75rem" border="1px solid #ccc">
                        <Accordion allowToggle key={year}>
                            <AccordionItem border="none">
                                <AccordionButton
                                    padding="0.75rem 1rem"
                                    rounded="0.75rem"
                                    transition="border-radius 0.5s ease-in-out"
                                    sx={{
                                        "&[aria-expanded=true]": {
                                            rounded: "0.75rem 0.75rem 0 0",
                                        },
                                    }}
                                    _focus={{ outline: "none" }}
                                >
                                    <Box as="span" flex="1" fontWeight="700" textAlign="left">
                                        {year}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel p="0">
                                    <Flex flexDirection="column">
                                        {events?.map((sub) => (
                                            <Flex
                                                padding="0.75rem 1rem"
                                                gridGap={4}
                                                justifyContent="space-between"
                                                key={sub.date}
                                                borderTop="1px solid #cfcfcf"
                                                sx={{
                                                    "&:last-of-type": {
                                                        rounded: "0 0 0.75rem 0.75rem",
                                                    },
                                                }}
                                                _hover={{
                                                    bgColor: "#e0e0e0",
                                                }}
                                            >
                                                <Text fontWeight="400" fontSize="sm" color={PodmeColor.LicoriceBlue}>
                                                    {GetUserFriendlyEventName(sub.subscriptionType)}
                                                </Text>
                                                <Tooltip label={GetPlanNameOnPlanChangeTooltip(sub)} hasArrow fontSize="xs">
                                                    <Text fontWeight="400" fontSize="xs" noOfLines={1}>
                                                        {GetPlanNameOnPlanChange(sub)}
                                                    </Text>
                                                </Tooltip>
                                                <Text fontWeight="400" fontSize="sm">
                                                    {format(new Date(sub.date), "yyyy-MM-dd HH:mm")}
                                                </Text>
                                            </Flex>
                                        ))}
                                    </Flex>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
}

export default SubscriptionEvents;
