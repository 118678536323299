import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Box,
    AccordionIcon,
    AccordionButton,
    AccordionPanel,
    Accordion,
    AccordionItem,
    Link,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { RemovedByType, SubscriptionGuestActionType, SubscriptionGuestHistory } from "../../redux/types";

interface Props {
    subscriptionGuestHistoryList: SubscriptionGuestHistory[];
    includeUserIdColumn?: boolean;
}

function SubscriptionGuestHistoryList({ subscriptionGuestHistoryList, includeUserIdColumn = true }: Props) {
    return (
        <Flex flexDirection="column" backgroundColor="#e9e9e9" rounded="0.75rem" border="1px solid #ccc">
            <Accordion allowToggle key={0}>
                <AccordionItem border="none">
                    <AccordionButton
                        padding="0.75rem 1rem"
                        rounded="0.75rem"
                        transition="border-radius 0.5s ease-in-out"
                        sx={{
                            "&[aria-expanded=true]": {
                                rounded: "0.75rem 0.75rem 0 0",
                            },
                        }}
                        _focus={{ outline: "none" }}
                    >
                        <Box as="span" flex="1" fontWeight="700" textAlign="left">
                            Subscription Guest History
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p="0">
                        <Flex direction="column" gridGap="1rem">
                            <Flex direction="column" gridGap="0.5rem">
                                <Table borderColor="#cfcfcf">
                                    <Thead fontSize="sm">
                                        <Tr>
                                            {includeUserIdColumn && (
                                                <Th textAlign="left" background="#cfcfcf">
                                                    <Text>User Id</Text>
                                                </Th>
                                            )}
                                            <Th textAlign="left" background="#cfcfcf">
                                                <Text>Action</Text>
                                            </Th>
                                            <Th textAlign="left" background="#cfcfcf">
                                                <Text>Reason</Text>
                                            </Th>
                                            <Th textAlign="right" background="#cfcfcf">
                                                <Text>Timestamp</Text>
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody fontSize="sm">
                                        {subscriptionGuestHistoryList?.map((history, idx) => {
                                            return (
                                                <Tr key={idx}>
                                                    {includeUserIdColumn && (
                                                        <Td textAlign="left" borderColor="#cfcfcf" borderTop="1px" borderBottom="1px">
                                                            <Link href={`/users/${history.userId}`}>
                                                                <Text
                                                                    as="span"
                                                                    fontWeight="500"
                                                                    fontSize="0.75rem"
                                                                    color={"#256199"}
                                                                    textTransform="uppercase"
                                                                >
                                                                    {history.userId}
                                                                </Text>
                                                            </Link>
                                                        </Td>
                                                    )}
                                                    <Td textAlign="left" borderColor="#cfcfcf" borderTop="1px" borderBottom="1px">
                                                        <Text as="span" fontWeight="500" fontSize="0.75rem" textTransform="uppercase">
                                                            {SubscriptionGuestActionType[history.action]}
                                                        </Text>
                                                    </Td>
                                                    <Td textAlign="left" borderColor="#cfcfcf" borderTop="1px" borderBottom="1px">
                                                        <Text as="span" fontWeight="500" fontSize="0.75rem">
                                                            {RemovedByType[history.removedBy]}
                                                        </Text>
                                                    </Td>
                                                    <Td textAlign="right" borderColor="#cfcfcf" borderTop="1px" borderBottom="1px">
                                                        <Text as="span" fontWeight="500" fontSize="0.75rem" textTransform="uppercase">
                                                            {format(new Date(history.createdAt), "yyyy-MM-dd HH:mm")}
                                                        </Text>
                                                    </Td>
                                                </Tr>
                                            );
                                        })}
                                    </Tbody>
                                </Table>
                                {subscriptionGuestHistoryList.length === 0 && (
                                    <Box textAlign={"center"}>
                                        <Text>No history records was found.</Text>
                                    </Box>
                                )}
                            </Flex>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Flex>
    );
}

export default SubscriptionGuestHistoryList;
